import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductionHistoryService } from '../../../services-http/production-history/production-history.service';
import { FormBuilder, Validators,FormGroup,FormControl } from '@angular/forms';
import Swal from 'sweetalert2'
import { ModalDirective } from 'ng-uikit-pro-standard';
import { BillingService } from '../../../services-http/billing/billing.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IMyOptions } from 'ng-uikit-pro-standard';


@Component({
  selector: 'app-cac-wallet',
  templateUrl: './cac-wallet.component.html',
  styleUrls: ['./cac-wallet.component.scss']
})
export class CacWalletComponent implements OnInit {
  @ViewChild('basicModal') basicModal: ModalDirective;
  @ViewChild('frame') frame: ModalDirective;

  constructor(
    private productHis:ProductionHistoryService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private bill:BillingService,
    ) { }

  formData = new FormData();
  cashThruECPay:boolean =false;
  cashThruTrueMoney:boolean =false;
  cashThruRemitCenterBanks:boolean =false;
  cashThruGcash:boolean =false;
  cashThruSOA:boolean=false;

  cashinType:any = "";
  CashIn_pending:any = [];
  optionsSelect: Array<any>;
  file: File;
  transactions:any = [];
  max = 100000;
  min = 999999;
  show:boolean = true;
  random_number:any;
  OTP:number;
  points:any = {points:0};
  sent_to:any= [];
  channel_name_list:any = [];
  channel_type:any = [];
  interval;
  buttonshow:boolean=true;
  timeLeft: number = 5;
  users = JSON.parse(localStorage.getItem('currentUser'));
  val:any ="transaction_type";
  check:boolean =false;
  title:any = "";
  date_from:any;
  date_to:any;

  // recipients:any =[
    // {label: 'Branch', value:'branch'},
  //   {label: 'Agent/ Branch', value:'agent'}
  // ];

  public myDatePickerOptions: IMyOptions = {
    // Your options
  };

  public myDatePickerOptions1: IMyOptions = {
    // Your options
  };

  profileForm = this.fb.group({
    agent:  ['', Validators.required],
    amount: ['',[Validators.required,  Validators.pattern(/^[0-9.]*$/)  ]]
  });


  SOA = this.fb.group({
    sender_name:  [this.users.name,Validators.required],
    amount_sent:  ['',[Validators.required, Validators.pattern(/^[0-9.]*$/) ]],
    reference_no: ['',Validators.required],
    deposit_date: ['',Validators.required]
  });


  cashInForm = new FormGroup({
    cashInAmount: new FormControl('', [ Validators.required, Validators.min(1), Validators.pattern("^[0-9.]*$") ] ),
  })

  CashIn = this.fb.group({
    channel_type:  ['', Validators.required],
    channel_branch:['', Validators.required],
    channel_name:  ['', Validators.required],
    sender_name:   [''],
    amount_sent:   ['', [Validators.required, Validators.min(1), Validators.pattern("^[0-9.]*$") ]],
    mobile_no:     [''],
    email_add:     [''],
    reference_no:  ['', Validators.required],
    account:       [''],
    deposit_date:  ['', Validators.required]
  });


  ngOnInit() {
    this.get_points();
    this.get_channel_type();
    this.get_cashIn_pending();
    this.get_agents();
    this.get_transaction(1)

    this.CashIn.get("channel_name").valueChanges.subscribe(x => {
      if(x){
        this.CashIn.patchValue({account: this.cashThruSOA?'': this.channel_name_list.find(y=>y.value == x).account});
      }
    });
  }


  get sender_name() {return this.CashIn.get('sender_name');}
  get email_add() {return this.CashIn.get('email_add');}
  get mobile_no() {return this.CashIn.get('mobile_no');}
  get amount_sent() {return this.CashIn.get('amount_sent');}
  get channel_branch() {return this.CashIn.get('channel_name');}
  get reference_no() {return this.CashIn.get('reference_no');}
  get account() {return this.CashIn.get('account');}
  get amount() {return this.profileForm.get('amount');}
  get cashInAmount() {return this.cashInForm.get('cashInAmount');}



  checkbox(e){
    if(e.target.checked ==true){
      this.get_cashIn_pending();
    }
  }  

  isSaveClicked = false;

  onSaveClick(): void {
    // Trigger the save logic
    this.isSaveClicked = true;
  
    // Mark all controls as touched and dirty to display validation errors
    Object.keys(this.CashIn.controls).forEach(controlName => {
      const control = this.CashIn.get(controlName);
      if (control) {
        control.markAsTouched();
        control.markAsDirty();
      }
    });
  
    // If the form is invalid, prevent the save operation
    if (this.CashIn.invalid) {
      console.log('Form is invalid');
      return;
    }
  
    // Call your save logic if the form is valid
    this.save_cashIn();
  }

  


  get_points(){
    let data ={
      login_id: this.users.iloginId
    }
    this.spinner.show();
    this.productHis.get_points(data).subscribe(res=>{
      this.spinner.hide();
      this.points = res;
    },error=>{
      this.spinner.hide();
    });
  }


  // choose_recipient(){
  //   if(this.profileForm.value.recipient == 'agent'){
  //     this.get_agents();
  //   }
  //   else if(this.profileForm.value.recipient == 'branch'){
  //     this.get_branch();
  //   }
  // }

  get_agents(){
    // let data ={
    //   points: this.users.iloginId
    // }
  
    this.productHis.get_agents(this.users.iloginId).subscribe(res=>{ 
      this.sent_to = res;
    });
  }


  // get_branch(){
  //   let data ={
  //     coop_no: this.users.coop_maincode
  //   }
  
  //   this.productHis.get_branch(data).subscribe(res=>{ 
  //      this.sent_to = res;
  //   });

  // }


  get_transaction(page){
    this.spinner.show();
    let data = {
      iloginId: this.users.iloginId,
      filter : this.val,
      page: page
    }

    this.productHis.get_transaction(data).subscribe(res=>{
      this.transactions = res;
      this.spinner.hide();
    },error=>{
      this.spinner.hide();
    });
  }


  send(){
    this.timeLeft = 5;
    this.randomizer();
    this.startTimer();

    let data = {
      random_number :this.random_number,
      mobile_number: this.users.contact_no,
      amount: +this.profileForm.value.amount,
      posting_type: 0,
      login_id: this.users.iloginId
    };
   
    this.productHis.send_otp(data).subscribe(res=>{
      this.show = false;
      
    },error=>{
      let err = error.error;
      let msg='';

      Object.keys(err).forEach(function(key){
        msg+=err[key];
      });
        
      Swal.fire(
        'Oopps',
        msg,
        'error'
      );
    });
  }

  request_again(){
    this.buttonshow =true;
    this.send();
  }

  submit(){
    let data = {
      amount        : this.profileForm.value.amount,
      from_login_id : this.users.iloginId,
      to_login_id   : this.profileForm.value.agent,
      otpcode       : this.OTP,
      posting_type  : 0
    }
  
    if(this.random_number != this.OTP){
      Swal.fire(
        'Error',
        'OTP does not matched!',
        'error'
      );
      return;
    }

    this.productHis.submit_amount(data).subscribe(res=>{  
      this.basicModal.hide();

      this.randomizer();
      this.points.points = +this.points.points - +this.profileForm.value.amount;
      clearInterval(this.interval);
      this.buttonshow = true;
      this.show = true;

      Swal.fire(
        'Success',
        'Successfully Transfer Funds!',
        'success'
      ); 
    });
  
  }

  randomizer(){
    this.random_number = Math.floor(Math.random() * (this.max - this.min + 1)) + this.min;
  }

 
  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        // this.timeLeft = 5;
        this.randomizer();
        clearInterval(this.interval);
        this.buttonshow = false;
      }
    },60000)
  }



  onFileSelected(event) {
    if(  this.formData.getAll('file').length !=0 ){
      alert('oopps. If you want to replace, remove previous deposit slip!')
      return
    }
    this.formData.append('file', event.target.files[0], event.target.files[0].name);
  }

  onFileDelete(){
    this.formData.delete('file');
  }


  save_cashIn(){
    this.spinner.show();

    var splitted_Channel = !this.channel_branch.value?'' :this.channel_branch.value.split('/');
    let data={
      channel_type: this.cashThruSOA? 0 :this.CashIn.value.channel_type,
      channel_name: this.cashThruSOA? 'Climbs account' :splitted_Channel[1],
      channel_branch: this.cashThruSOA? 'Statement of Account' :this.channel_branch.value,
      sender_name: this.cashThruSOA? this.SOA.value.sender_name :this.users.name,
      amount_sent: this.cashThruSOA? this.SOA.value.amount_sent :this.CashIn.value.amount_sent,
      reference_no: this.cashThruSOA? this.SOA.value.reference_no :this.CashIn.value.reference_no,
      login_id: this.users.iloginId,
      mobile_no: this.users.contact_no,
      email_add: this.users.username,
      account: this.cashThruSOA? 'N/A':this.CashIn.value.account,
      deposit_date: this.cashThruSOA? this.SOA.value.deposit_date :this.CashIn.value.deposit_date,
    }

    this.formData.set('data', JSON.stringify(data));
    this.productHis.save_cashIn(this.formData).subscribe(res=>{
        this.CashIn.reset();
        this.SOA.reset();

        this.onFileDelete();
        this.frame.hide();
        this.spinner.hide();
        Swal.fire(
          'Success',
          res['msg'],
          'success'
        );
    },error=>{
      this.spinner.hide();
      Swal.fire(
        'Error',
        'Something went wrong! ',
        'error'
      );
    }); 

  }

  get_channel_branch(val){

    if(val){
      this.productHis.get_channel_branch(val).subscribe(res=>{
        this.channel_name_list = res;
      });
    }
   
  }

  get_channel_type(){
    this.productHis.get_channel_type().subscribe(res=>{
      this.channel_type = res;
    });

  }

  get_cashIn_pending(){
    this.productHis.get_cashIn_pending(this.users.iloginId).subscribe(res=>{
      this.CashIn_pending = res
    });
  }


  async cashIn() {
    this.frame.hide();
    let data = {
      loginId: this.users.iloginId,
      amount: this.cashInForm.value.cashInAmount,
      cashinType:this.cashinType
    }
 
    this.productHis.cashInUsingRemittanceOrBank(data).subscribe(res => {
      Swal.fire(
        'Success',
        res['msg'],
        'success'
      );
  
    },error=>{
      Swal.fire(
        'Error',
        "Something went wrong!",
        'error'
      );
    }
    )
  }


  export_trans_history(){
    this.spinner.show();

    let data ={
      date_from: this.date_from,
      date_to: this.date_to,
      trans_type: this.val,
      posting_type: 0
    }

    this.bill.export_trans_history(data).subscribe((res:any)=>{
      this.spinner.hide();
      window.open(res);
    },error=>{
      this.spinner.hide();
    });
  }
  

}
